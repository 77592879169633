import React, {useEffect} from "react";
import { Card } from "primereact/card";
import { useState, useRef } from "react";
//import axios from "axios";
import { useHistory,useParams,useLocation } from "react-router-dom";
import { Toast } from "primereact/toast";
import LoadingOverlay from "react-loading-overlay";
import { DealService } from '../service/DealService';
import "./customButton.css";
import configData from "../config.json";
export const EmailVerify = () => {
    //const history = useHistory();
    //const params = useParams()
    const useQuery= () => {
        return new URLSearchParams(useLocation().search);
 }
 let query = useQuery();
 console.log("Get data==>",query.get('token'));
    const [isActive, setActive] = useState(false);
    const toast = useRef(null);
    
    useEffect(() => {
        const dealsService = new DealService();
        const data = {
            "token": query.get('token')
        }
        const config = {
            method: 'POST',
            url: configData.SERVER_URL+'home/user/signup_token_verify',
            headers: {
                'Content-Type' : 'application/json'
            },
            data: data
        }
        dealsService.getDeals(config).then(data => setActive(data["data"]));
        
    }, []);

    return (
        <>
        <LoadingOverlay active={isActive} spinner>
              
            <Toast ref={toast} />{" "}
            <Card className="p-shadow-7 formcard">
                <h2 className="logintitle"> Email Varifiction Page </h2>{" "}
                    Your email varifiction successfully. Please login your Apps
                <br />
            </Card>{" "}
            <div>
                <div className="copyRightText"> {/* <p>© {new Date().getFullYear()} MBE</p> */} </div>{" "}
            </div>
            </LoadingOverlay>{" "}
        </>
    );
};
