import React, { useState, useEffect } from 'react';
//import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../service/ProductService';
import { BrandService } from '../service/BrandsService';
//import { DashBoardCards} from './Card';
import { DashBoardTopBrandsCards} from './TopBrandsCard';
//import {lineData} from "../Data/graphsData";
//import {DashBoardTopSellingProducts} from "./TopSellingProductList";
import configData from "../config.json";
export const Dashboard = () => {

    const [products, setProducts] = useState(null);
    const [brands, setBrands] = useState(null);
    //const [users,setUsers]=useState(0)
   // const [selectedDate, setSelectedDate] = useState([new Date(new Date().getFullYear(), new Date().getMonth(), 1),new Date()]);
   // const [selectedDateF, setSelectedDateF] = useState(null);
    //const dt = useRef(null);
    //const [fs,setFs] = useState("")
    //const [loading2, setLoading2] = useState(false);
    useEffect(() => {
        const productService = new ProductService();
        const brandsService = new BrandService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL+'category',
            headers: {
                'Content-Type' : 'application/json'
            }
        }
        brandsService.getBrands(config).then(data => setBrands(data.data));
        const config22 = {
            method: "get",
            url: configData.SERVER_URL+"songs",
            headers: {
                "Content-Type": "application/json",
            },
        };
        productService.getProduct(config22).then((data) => {setProducts(data.data.reverse())});
        
        //let month = new Date().getMonth() + 1;
        //let date1 = new Date().getFullYear() + "-" + month + "-01";
        //let date2 = new Date().getFullYear() +"-"+ month +"-" + new Date().getDate();
        // const config3 = {
        //     method: "get",
        //     url: configData.SERVER_URL+"admin/dashboard?fromdate="+date1+"&todate="+date2,
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // };
        // productService.getProduct(config3).then((data) => {
        //     setOrders(data.orders);setRevenue(data.revenue);setProfit(data.profit);setTProducts(data.products);setUsers(data.users)});
    }, []);

    // const formatDate = (date) => {
    //     let month = date.getMonth() + 1;
    //     let day = date.getDate();

    //     if (month < 10) {
    //         month = '0' + month;
    //     }

    //     if (day < 10) {
    //         day = '0' + day;
    //     }

    //     return date.getFullYear() + '-' + month + '-' + day;
    // }

    // const onDateChange = (e) => {
    //     const productService = new ProductService();
    //     if (e.value[1]!==null){
    //         let dat2 = formatDate(e.value[1])
    //         setSelectedDate(e.value);
    //     }
    //     if (e.value[0]!==null && e.value[1]===null){
    //         let dat2 = formatDate(e.value[0])
    //         setFs(dat2)
    //         setSelectedDate(e.value);
    //         return
    //     }
    // }

    // const formatCurrency = (value) => {
    //     return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    // };
    // const reset = () => {
    //     setSelectedDateF(null);
    //     setSelectedDate(undefined);
    //     //dt.current.reset();
    // }

    // const getAll = () => {
    //     setLoading2(true);
    //     const productService = new ProductService();
    //     setSelectedDateF(null);
    //     setSelectedDate(undefined);
    //     let dat1 = "2022-04-01";
    //     let month = new Date().getMonth() + 1;
    //     let dat2 = new Date().getFullYear() +"-"+ month +"-" + new Date().getDate();
        
    // }
   

    return (
        <>
      
        <div className="grid">
           <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Recent Songs</h5>
                    <DataTable value={products} className="p-datatable-customers" rows={5} paginator>
                        <Column field="title" header="Name" sortable/>
                        <Column field="content" header="Description" sortable />
                        <Column header="Image" body={(data) => <img src={data.image} alt={data.image} width="50" />} />
                        
                    </DataTable>
                </div>
               
            </div>

            <div className="col-12 xl:col-6">
               <DashBoardTopBrandsCards brands={brands} />
            </div>
        </div>
        </>
    );
}
