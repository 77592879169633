import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
//import { FileUpload } from 'primereact/fileupload';
import "./customButton.css"
import { UserService } from '../service/UsersService';
import { Toast } from 'primereact/toast';
import { CategoryService } from "../service/CategoryService";
import { Dropdown } from 'primereact/dropdown';
import configData from "../config.json";
export const AdminUserLayout = () => {
    const history = useHistory();
    const toast = useRef(null);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [pass, setPass] = useState("")
    const [cpass, setCPass] = useState("")
    const [roles, setRole] = useState("")
    const [selectedRoles, setSelectedRole] = useState(null);

    useEffect(() => {
        const categoryService = new CategoryService();
        const config = {
            method: "get",
            url: configData.SERVER_URL + "user/roles",
            headers: {
                "Content-Type": "application/json",
            },
        };
        categoryService
            .getCategories(config)
            .then((data) => setRole(data.data));

    }, []);

    const onSubmit = () => {
        const userService = new UserService();

        let errors = {};
        if (!name) {
            errors.name = 'name is required.';
            toast.current.show({ severity: 'error', summary: 'Error', detail: errors.name, life: 3000 });
            return;
        }

        if (!email) {
            errors.email = 'email is required.';
            toast.current.show({ severity: 'error', summary: 'Error', detail: errors.email, life: 3000 });
            return;
        }

        if (!phone) {
            errors.phone = 'phone  is required.';
            toast.current.show({ severity: 'error', summary: 'Error', detail: errors.phone, life: 3000 });
            return;
        }
        if (!pass) {
            errors.password = 'password  is required.';
            toast.current.show({ severity: 'error', summary: 'Error', detail: errors.password, life: 3000 });
            return;
        }
        if (!cpass) {
            errors.cpass = 'confirm password  is required.';
            toast.current.show({ severity: 'error', summary: 'Error', detail: errors.cpass, life: 3000 });
            return;
        }

        if (pass === cpass) {

            const user = {
                "name": name,
                "email": email,
                "phone": phone,
                "password": pass,
                "cpassword": pass,
                "status": "Active",
                "role": selectedRoles.role_id
            }
            const config = {
                method: 'post',
                url: configData.SERVER_URL + 'admin/register',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: user
            }
            userService.addUsers(config).then(data => {
                if (data.success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'User added successfully', life: 3000 });
                    history.push('/manage_adminusers');
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while adding a new user', life: 3000 });
                }
            });
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error password not matched', life: 3000 });
        }
    }
    return (
        <>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Add Site Users</h5>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12" style={{ marginTop: "20px" }}>
                                <label htmlFor="dealName">Select Roles</label>
                                <Dropdown value={selectedRoles} options={roles} onChange={(e) => setSelectedRole(e.target.value)} optionLabel="title" placeholder="Select a User Role" />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="Name">Name</label>
                                <InputText id="name" type="text" onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="Name">Email</label>
                                <InputText id="email" type="text" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="Name">Phone</label>
                                <InputText id="phone" type="text" onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="Name">Password</label>
                                <InputText id="password" type="password" onChange={(e) => setPass(e.target.value)} />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="Name">Confirm Password</label>
                                <InputText id="cpassword" type="password" onChange={(e) => setCPass(e.target.value)} />
                            </div>


                            <div className="field col-12">
                                <Button className="p-button-rounded formButton1" onClick={onSubmit}>Add Users</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
