import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CategoryService } from "../service/CategoryService";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import configData from "../config.json";


export const VideoTable = () => {
  const fileInput = useRef();
  let emptyVideo = {
    name: "",
    image: null,
    author:"",
    album:"",
    content:""
  };
  
  const [video, setVideo] = useState(null);
  const [category1, setCategory1] = useState(null);
  //const [category, setCategory] = useState(null);
  const toast = useRef(null);
  const [deleteVideoDialog, setDeleteVideoDialog] = useState(false);
  const [relistCategoryDialog, setRelistCategoryDialog] = useState(false);
  const [activeCategoryDialog, setActiveCategoryDialog] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState(emptyVideo);
  const [edit, setEditUpdate] = useState(false);
  const [images, setImage] = useState("");
  const [cid, setCid] = useState("");
  const [commentDetails, setCommentDetails] = useState(null);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentOrderDetails, setCurrentOrderDetails] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  
  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "songs/videos",
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService
      .getCategories(config)
      .then((data) => setVideo(data.data));

      const config1 = {
        method: "get",
        url: configData.SERVER_URL + "category",
        headers: {
          "Content-Type": "application/json",
        },
      };
      categoryService
        .getCategories(config1)
        .then((data) => setCategory1(data.data));

  }, []);

  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "songs/videos",
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService
      .getCategories(config)
      .then((data) => setVideo(data.data));

      const config1 = {
        method: "get",
        url: configData.SERVER_URL + "category",
        headers: {
          "Content-Type": "application/json",
        },
      };
      categoryService
        .getCategories(config1)
        .then((data) => setCategory1(data.data));
  }, [
    deleteVideoDialog,
    relistCategoryDialog,
    edit,
    categoryDetails,
    activeCategoryDialog,
    category1
  ]);

  const activestatusBodyTemplate = (rowData) => {
    if (rowData.active === true) {
      return (
        <>
          <span className={`product-badge status-instock`}>
            Active
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={`product-badge status-outofstock`}>Not Active</span>
        </>
      );
    }
  };

  const onCategoryChange = (e) => {
    setSelectedCategory(e.value);
  }

  const hideDeleteCategoryDialog = () => {
    setDeleteVideoDialog(false);
  };
  const hideActiveCategoryDialog = () => {
    setActiveCategoryDialog(false);
  };
  const hideRelistCategoryDialog = () => {
    setRelistCategoryDialog(false);
  };

  const deleteCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "delete",
      url: configData.SERVER_URL + "songs/delete-video/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { _id: cid },
    };
    categoryService.deleteCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Video delete successfully",
          life: 3000,
        });
        setDeleteVideoDialog(false);
        setCategoryDetails(emptyVideo);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while delete Video",
          life: 3000,
        });
      }
    });
  };

  const activeCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "put",
      url: configData.SERVER_URL + "songs/update_video/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { active: false },
    };
    categoryService.deleteCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Video unlisted successfully",
          life: 3000,
        });
        setActiveCategoryDialog(false);
        setCategoryDetails(emptyVideo);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while unlisting Video",
          life: 3000,
        });
      }
    });
  };

  const relistCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "put",
      url: configData.SERVER_URL + "songs/update_video/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { active: true },
    };
    categoryService.relistCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Video relisted successfully",
          life: 3000,
        });
        setRelistCategoryDialog(false);
        setCategoryDetails(emptyVideo);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while relisting Video",
          life: 3000,
        });
      }
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _category = { ...categoryDetails };
    _category[`${name}`] = val;

    setCategoryDetails(_category);
  };

  const [categoryUpdateDialog, setCategoryUpdateDialog] = useState(false);

  const updateVideo = () => {
    const categoryService = new CategoryService();

    let errors = {};
    if (!categoryDetails.title) {
      errors.name = "Name is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.title,
        life: 3000,
      });
      return;
    }

    if (!images) {
      errors.image = "image is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.images,
        life: 3000,
      });
      return;
    }

    

    const body = {
      title: categoryDetails.title,
      image: images,
      content: categoryDetails.content,
      album: categoryDetails.album,
      author: categoryDetails.author,
    };
    if (edit === true) {
      const config = {
        method: "put",
        url: configData.SERVER_URL + "songs/update_video/" + cid,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      categoryService.updateCategory(config).then((data) => {
        if (data.success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Video updated successfully",
            life: 3000,
          });
          setCategoryUpdateDialog(false);
          setCategoryDetails(emptyVideo);
          setEditUpdate(false);
          setCid("");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while updating Video",
            life: 3000,
          });
        }
      });
      return;
    }
  };

  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };
  const onUpload = (event) => {
    const categoryService = new CategoryService();
    let file = fileInput.current.files[0];
    
    let data = new FormData();
    data.append('file', file);
    
    const config = {
      method: "post",
      url: configData.SERVER_URL + "songs/upload",
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      data: data,
    };
    categoryService.addCategory(config).then((data) => {
      if (data) {
        setImage(configData.SERVER_URL+'songs/'+data.filename);
        let _category = { ...categoryDetails };
       _category["image"] = images;
       setCategoryDetails(_category)

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Images added successfully",
          life: 3000,
        });
        
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new images",
          life: 3000,
        });
      }
    });
    
   
  };

  
  const categoryUpdateDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          setCategoryUpdateDialog(false);
        }}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={updateVideo}
      />
    </>
  );

  const deleteCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteCategory}
      />
    </>
  );

  const activeCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideActiveCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={activeCategory}
      />
    </>
  );
  const relistCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideRelistCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={relistCategory}
      />
    </>
  );

  const editCategory = (category) => {
    setCategoryDetails({ ...category });
    setEditUpdate(true);
    setCid(category._id);
    setImage(category.image);
    setCategoryUpdateDialog(true);
    setSelectedCategory(category.category);
    console.log("selectedCategory", selectedCategory);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions" style={{
        display: "flex", width: "100px"
      }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editCategory(rowData)}
        />
        {rowData.active === true && (
          <Button
            icon="pi pi-lock "
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => {
              setCategoryDetails(rowData);
              setCid(rowData["_id"]);
              setActiveCategoryDialog(true);
            }}
          />
        )}
        {rowData.active === false && (
          <Button
            icon="pi pi-unlock"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => {
              setCategoryDetails(rowData);
              setCid(rowData["_id"]);
              setRelistCategoryDialog(true);
            }}
          />
        )}
        {
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => {
              setCategoryDetails(rowData);
              setCid(rowData["_id"]);
              setDeleteVideoDialog(true);
            }}
          />
        }
      </div>
    );
  };

  const [selectedStatF, setSelectedStatF] = useState(null);
  const dt = useRef(null);
  const statItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onStatChange = (e) => {
    dt.current.filter(e.value, "status", "equals");
    setSelectedStatF(e.value);
  };
  const statFilter = (
    <Dropdown
      value={selectedStatF}
      options={["Active", "Not Active"]}
      onChange={onStatChange}
      itemTemplate={statItemTemplate}
      placeholder="Select a Status"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const reset = () => {
    setGlobalFilter("");
    dt.current.reset();
  };
  
  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left" style={{ float: "right" }}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search"
          style={{ marginRight: "15px" }}
        />
      </span>
    </div>
  );

  const getCommentData = (id) => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "user/comments/" + id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService.deleteCategory(config).then((data) => {
      if (data) {
        setCommentDetails(data.data);
        setOpenCommentModal(true);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while fetch songs comments",
          life: 3000,
        });
      }
    });
  };


  return (
    <>
      <Toast ref={toast} />
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h5>Video List</h5>
            <DataTable
              ref={dt}
              value={video}
              className="datatable-responsive"
              rows={10}
              paginator
              globalFilter={globalFilter}
              header={header}
              dataKey="_id"
            >
              <Column
                header="Image"
                body={(data) => <img src={data.image} alt={data} width="50" />}
                style={{ fontSize: "12px"}}
              />
             
              <Column
                field="title"
                header="Name"
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCurrentOrderDetails(rowData);
                        setOpenModal(true);
                      }}
                    >
                      {rowData && rowData.title}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px"}}
              />
              <Column
                field="viewCount"
                header="Views"
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                      }}

                    >
                      {rowData && rowData.viewCount[0].count}
                    </span>
                  </>
                )}
                //sortable
                style={{ fontSize: "12px"  }}
              />
              <Column
                field="likesCount"
                header="Likes"
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                      }}

                    >
                      {rowData && rowData.likesCount}
                    </span>
                  </>
                )}
                //sortable
                style={{ fontSize: "12px"  }}
              />

              <Column
                field="commentsCount"
                header="Comments"
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => getCommentData(rowData._id)}
                    >
                      {rowData && rowData.commentsCount}
                    </span>
                  </>
                )}
                //sortable
                style={{ fontSize: "12px"}}
              />

              <Column
                field="createdAt"
                header="Date"
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      {rowData && new Date(rowData.createdAt).toLocaleString()}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px" }}
              />
              <Column
                field="status"
                header="Status"
                body={activestatusBodyTemplate}
                filter
                filterElement={statFilter}
                style={{ fontSize: "12px"}}
              />
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog
        header="Video Details"
        visible={openModal}
        style={{ width: "50vw" }}
        onHide={() => setOpenModal(false)}
        modal
      >
        <Divider />
        
        {currentOrderDetails && currentOrderDetails.url && (
          <video src={currentOrderDetails.url} width="550" height="300" controls></video>
        )}
      </Dialog>

      <Dialog
        header="Comments Details"
        visible={openCommentModal}
        style={{ width: "50vw" }}
        onHide={() => setOpenCommentModal(false)}
        modal
      >
        <Divider />
        {commentDetails  && (
          <DataTable value={commentDetails}>
          <Column
            field="username"
            header="Name"
            sortable
            style={{ fontSize: "12px" }}
          ></Column>
          <Column
            field="comment"
            header="Comment"
            style={{ fontSize: "12px" }}
          ></Column>
          <Column
                field="createdAt"
                header="Date"
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      {rowData && new Date(rowData.createdAt).toLocaleString()}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px" }}
              />
         
        </DataTable>
    
        )}

      
      </Dialog>

    
    
      <Dialog
        visible={categoryUpdateDialog}
        style={{ width: "650px" }}
        header="Video Details"
        modal
        className="p-fluid"
        footer={categoryUpdateDialogFooter}
        onHide={() => {
          setCategoryUpdateDialog(false);
        }}
      >
        {categoryDetails.image && (
          <img
            src={categoryDetails.image}
            alt={categoryDetails.image}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
        <div className="field col-12">
          <label htmlFor="categoryImage">Video image</label>
          <FileUpload
            ref={fileInput}
            name="demo"
            customUpload={true}
            uploadHandler={onTemplateUpload}
            onUpload={onTemplateUpload}
            accept="image/*"
            maxFileSize={1000000}
            emptyTemplate={
              <p className="p-m-3" style={{ fontSize: "15px" }}>
                Drag and drop files to here to upload.
              </p>
            }
            auto
          />
        </div>
        
        <div className="field">
          <label htmlFor="name">Title</label>
          <InputText
            id="name"
            value={categoryDetails.title}
            onChange={(e) => onInputChange(e, "title")}
            required
            autoFocus
          />
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <InputText
            id="description"
            value={categoryDetails.content}
            onChange={(e) => onInputChange(e, "content")}
            required
            autoFocus
          />
        </div>

        <div className="field">
          <label className="mb-3">Category</label>
          <Dropdown value={selectedCategory} options={category1} onChange={onCategoryChange} optionLabel="title"
          optionValue="_id" />
        </div>

        <div className="field">
          <label htmlFor="album">Album</label>
          <InputText
            id="album"
            value={categoryDetails.album}
            onChange={(e) => onInputChange(e, "album")}
            required
            autoFocus
          />
        </div>
        <div className="field">
          <label htmlFor="author">Author</label>
          <InputText
            id="author"
            value={categoryDetails.author}
            onChange={(e) => onInputChange(e, "author")}
            required
            autoFocus
          />
        </div>
      </Dialog>
      <Dialog
        visible={deleteVideoDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteCategoryDialogFooter}
        onHide={hideDeleteCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to delete Video{" "}
              <b>{categoryDetails.title}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={activeCategoryDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={activeCategoryDialogFooter}
        onHide={hideActiveCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to unlist Video{" "}
              <b>{categoryDetails.title}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={relistCategoryDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={relistCategoryDialogFooter}
        onHide={hideRelistCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to relist Video{" "}
              <b>{categoryDetails.title}</b> ?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};
