import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const DashBoardTopBrandsCards = (props) => {

    return (
        <div className="card">
            <h5>Top Category</h5>
            <DataTable value={props.brands} className="p-datatable-customers" rows={5} paginator>
                <Column header="Image" body={(data) => <img src={data.image} alt={data.image} width="50" />} />
                <Column field="title" header="Name" sortable />
               {/* <Column
                    header="View"
                    body={() => (
                        <>
                            <Button icon="pi pi-search" type="button" className="p-button-text" onClick=/>
                        </>
                    )}
                /> */}
            </DataTable>
        </div>
    );
};
