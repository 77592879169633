import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";

import { Dashboard } from "./components/Dashboard";
import { CategoryFormLayout } from "./components/CategoryFormLayout";
import { LoginPageComponent } from "./components/LoginPageComponent";
import { CategoryTable } from "./components/CategoryTable";

import { UserTable } from "./components/UsersTable";
import PrimeReact from "primereact/api";
import { GeneralFormLayout } from "./components/GeneralFormLayout";
import { EmailVerify } from "./components/EmailVerify";
import { ProductPage } from "./components/ProductPage";
import { AdminUserTable } from "./components/AdminUserTable";
import { TMCTable } from "./components/TMCTable";
import { UserService } from "./service/UsersService";
import { SongsTable } from "./components/SongsTable";
import { SongsFormLayout } from "./components/SongsFormLayout";
import { VideoTable } from "./components/VideoTable";
import { VideoFormLayout } from "./components/VideoFormLayout";
import { AdminUserLayout } from "./components/AdminUserFormLayout";
import { PrivacyTable } from "./components/PrivacyTable";
import { AboutTable } from "./components/AboutTable";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import configData from "./config.json";

const App = () => {
  let location = useLocation();
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [users, setUsers] = useState(null);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
    const userService = new UserService();
    let token = sessionStorage.getItem("token");
    //console.log("token", token);
    // if (token) {
    //   const config = {
    //     method: "get",
    //     url: configData.SERVER_URL+"home/user/profilebytoken",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + token,
    //     },
    //   };
    //   userService
    //     .getUsers(config)
    //     .then((data) => setUsers(data["userDetails"]));
    //   console.log("users", users);
    // }
  }, [mobileMenuActive]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Home",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
        },
      ],
    },
    
    {
      label: "Categories",
      items: [
        {
          label: "Add Category",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addcategory",
        },
        {
          label: "Manage Categories",
          icon: "pi pi-fw pi-microsoft",
          to: "/managecategory",
        },
      ],
    },
   
    {
      label: "Songs",
      items: [
        {
          label: "Add Songs",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addsongs",
        },
        {
          label: "Manage Songs",
          icon: "pi pi-fw pi-id-card",
          to: "/managesongs",
        },
      ],
    },
    {
      label: "Videos",
      items: [
        {
          label: "Add Video",
          icon: "pi pi-fw pi-plus-circle",
          to: "/addvideo",
        },
        {
          label: "Manage Video",
          icon: "pi pi-fw pi-filter",
          to: "/managevideos",
        },
      ],
    },
    {
      label: "Users",
      items: [
        {
          label: "Manage App Users",
          icon: "pi pi-fw pi-users",
          to: "/manageusers",
        },
        {
          label: "Manage Admin Users",
          icon: "pi pi-fw pi-users",
          to: "/manage_adminusers",
        },
        {
          label: "Add Admin Users",
          icon: "pi pi-fw pi-users",
          to: "/adminusers",
        },
      ],
    },
    // {
    //   label: "Chat",
    //   items: [
    //     {
    //       label: "Manage Chats",
    //       icon: "pi pi-fw pi-comments",
    //       to: "/chat",
    //     },
    //   ],
    // },
    // {
    //   label: "Transactions",
    //   items: [
    //     {
    //       label: "Manage Transactions",
    //       icon: "pi pi-fw pi-credit-card",
    //       to: "/transactions",
    //     },
    //   ],
    // },
    // {
    //   label: "Orders",
    //   items: [
    //     {
    //       label: "Manage Orders",
    //       icon: "pi pi-fw pi-shopping-cart",
    //       to: "/orders",
    //     },
    //     {
    //       label: "Cancel Orders",
    //       icon: "pi pi-fw pi-shopping-cart",
    //       to: "/cancelorders",
    //     },
    //   ],
    // },
    // {
    //   label: "Delivery City",
    //   items: [
    //     {
    //       label: "Add Delivery City",
    //       icon: "pi pi-fw pi-plus-circle",
    //       to: "/add_delivery_city",
    //     },
    //     {
    //       label: "Manage Delivery City",
    //       icon: "pi pi-fw pi-id-card",
    //       to: "/delivery_city",
    //     },
    //   ],
    // },
    // {
    //   label: "Shipping",
    //   items: [
    //     {
    //       label: "Add Shipping",
    //       icon: "pi pi-fw pi-plus-circle",
    //       to: "/addshipping",
    //     },
    //     {
    //       label: "Manage Shipping",
    //       icon: "pi pi-fw pi-id-card",
    //       to: "/shipping",
    //     },
    //   ],
    // },
    // {
    //   label: "Tax",
    //   items: [
    //     {
    //       label: "Add tax",
    //       icon: "pi pi-fw pi-plus-circle",
    //       to: "/addtax",
    //     },
    //     {
    //       label: "Manage tax",
    //       icon: "pi pi-fw pi-id-card",
    //       to: "/tax",
    //     },
    //   ],
    // },
    {
      label: "General Settings",
      items: [
        // {
        //   label: "Add General Setting",
        //   icon: "pi pi-fw pi-plus-circle",
        //   to: "/addgeneralsettings",
        // },
        {
          label: "Manage T&C",
          icon: "pi pi-fw pi-plus-circle",
          to: "/term_condition",
        },
        {
          label: "Manage Privacy Policy",
          icon: "pi pi-fw pi-plus-circle",
          to: "/privacy_policy",
        },
        {
          label: "Manage About us",
          icon: "pi pi-fw pi-plus-circle",
          to: "/about_us",
        },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <>
      {sessionStorage.getItem("token") ? (
        <div className={wrapperClass} onClick={onWrapperClick}>
          <AppTopbar
            profile={users}
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
          />

          <div className="layout-sidebar" onClick={onSidebarClick}>
            <AppMenu
              model={menu}
              onMenuItemClick={onMenuItemClick}
              layoutColorMode={layoutColorMode}
            />
          </div>

          <div className="layout-main-container">
            <div className="layout-main">
              <Switch>
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/addcategory" component={CategoryFormLayout} />
                <Route path="/managecategory" component={CategoryTable} />
                <Route path="/addsongs" component={SongsFormLayout} />
                <Route path="/managesongs" component={SongsTable} />
                <Route path="/addvideo" component={VideoFormLayout} />
                <Route path="/managevideos" component={VideoTable} />
                 <Route path="/manageusers" component={UserTable} />
                <Route path="/manage_adminusers" component={AdminUserTable} />
                <Route path="/adminusers" component={AdminUserLayout} />
                <Route
                  path="/addgeneralsettings"
                  component={GeneralFormLayout}
                />
                <Route path="/term_condition" component={TMCTable} />
                <Route path="/privacy_policy" component={PrivacyTable} />
                <Route path="/about_us" component={AboutTable} />
              </Switch>
            </div>
            <AppFooter layoutColorMode={layoutColorMode} />
          </div>
         
        </div>
      ) : (
        <div>
          {(location.pathname && location.pathname == "/account-activation" && (
            <Switch>
              <Route path="/account-activation" exact component={EmailVerify} />
            </Switch>
          )) ||
            (location.pathname && location.pathname == "/product-page" && (
              <Switch>
                <Route path="/product-page" exact component={ProductPage} />
              </Switch>
            )) || <LoginPageComponent />}
        </div>
      )}
    </>
  );
};

export default App;
