import axios from 'axios';

export class CategoryService {

    getCategories(config) {
        return axios(config).then(res => res.data);
    }

    addCategory(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }

    updateCategory(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    deleteCategory(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
    relistCategory(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }

    getApiData(config){
        return axios(config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
           return error
        })
    }
}