import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DealService } from '../service/DealService';
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from "primereact/dropdown";
import configData from "../config.json";

export const PrivacyTable = () => {
    let emptyDeals = {
        page: "",
        title: "",
        content: ""
    };
    const [deals, setDeals] = useState(null);
    const [relistDealDialog, setRelistDealDialog] = useState(false);
    const [dealDetails, setDealDetails] = useState(emptyDeals);
    const toast = useRef(null);
    const [edit, setEditUpdate] = useState(false)
    //const [message, setMessage] = useState("")
    const [dealUpdateDialog, setDealUpdateDialog] = useState(false);
    const [did, setDid] = useState("")

    const [statusTypeD, setStatusTypeD] = useState(null);

    useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL + 'page?page=privacy_policy',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data["data"]));



    }, []);
    useEffect(() => {
        const dealsService = new DealService();
        const config = {
            method: 'get',
            url: configData.SERVER_URL + 'page?page=privacy_policy',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        dealsService.getDeals(config).then(data => setDeals(data["data"]));

    }, [relistDealDialog, edit, dealDetails]);

    const activestatusBodyTemplate = (rowData) => {
        if (rowData.active === true) {
            return (
                <>
                    <span className={`product-badge status-instock`}>Active</span>
                </>
            );
        } else {
            return (
                <>
                    <span className={`product-badge status-outofstock`}>Not Active</span>
                </>
            );
        }
    };


    const hideRelistDealDialog = () => {
        setRelistDealDialog(false);
    };


    const relistDeal = () => {
        const dealService = new DealService();
        const config = {
            method: "put",
            url: configData.SERVER_URL + "page/add_page",
            headers: {
                "Content-Type": "application/json",
            },
            data: { status: "Active", _id: did },
        };
        dealService.relistDeal(config).then((data) => {
            if (data) {
                toast.current.show({ severity: "success", summary: "Success", detail: "T & C successfully", life: 3000 });
                setRelistDealDialog(false);
                setDealDetails(emptyDeals);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while relisting T & C", life: 3000 });
            }
        });
    };


    const updateDeal = () => {
        const dealService = new DealService();
        if (edit === true) {
            let errors = {};
            if (!dealDetails.content) {
                errors.message = 'message is required.';
                toast.current.show({ severity: 'error', summary: 'Error', detail: errors.message, life: 3000 });
                return;
            }
            const config = {
                method: 'post',
                url: configData.SERVER_URL + "page/add_page",
                headers: {
                    'Content-Type': 'application/json'
                },
                data: dealDetails
            }
            dealService.updateDeals(config).then(data => {
                if (data.success === true) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: ' T&C updated successfully', life: 3000 });
                    setDealUpdateDialog(false);
                    setDealDetails(emptyDeals);
                    setEditUpdate(false);
                    setDid("");
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while updating T&C', life: 3000 });
                }
            });
            return
        }
    };

    const dealUpdateDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setDealUpdateDialog(false) }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={updateDeal} />
        </>
    );


    const relistDealDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRelistDealDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={relistDeal} />
        </>
    );
    const editDeal = (deal) => {
        setDealDetails({ ...deal });
        setEditUpdate(true)
        setDid(deal._id)
        //setMessage(deal.message)
        setStatusTypeD(deal.active === true ? "Active":"Not Active");
        setDealUpdateDialog(true);
   };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _deal = { ...dealDetails };
        _deal[`${name}`] = val;


        setDealDetails(_deal)
    };

    const onStatusChange = (e) => {
        setStatusTypeD(e.value);
        let _deal = { ...dealDetails };
        _deal["active"] = e.value === 'Active' ? true : false;
        setDealDetails(_deal)
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDeal(rowData)} />

            </div>
        );
    };
    const [selectedStatF, setSelectedStatF] = useState(null);
    const dt = useRef(null);
    const statItemTemplate = (option) => {
        return <span style={{ fontSize: "14px", fontWeight: 600, textTransform: 'capitalize' }}>{option}</span>;
    }
    const onStatChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatF(e.value);
    }
    const statFilter = <Dropdown value={selectedStatF} options={["Active", "Not Active"]} onChange={onStatChange} itemTemplate={statItemTemplate} placeholder="Select a Status" option showClear style={{ width: "110px", fontSize: '10px' }} />;

    return (
        <>
            <Toast ref={toast} />
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <h5>Privacy policy </h5>
                        <DataTable ref={dt} value={deals} className="p-datatable-customers" rows={5} paginator>
                            <Column field="content" header="Content" style={{ fontSize: "12px", width: "70%" }} />
                            <Column field="status" header="Status" body={activestatusBodyTemplate} filter filterElement={statFilter} />
                            <Column body={actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={dealUpdateDialog} style={{ width: "650px" }} header="T & C" modal className="p-fluid" footer={dealUpdateDialogFooter} onHide={() => { setDealUpdateDialog(false) }}>
                <div className="field">
                    <label htmlFor="name">Content</label>
                    <InputTextarea rows={5} cols={30} name="content" type="text" id="name" value={dealDetails.content} onChange={(e) => onInputChange(e, "content")} required autoFocus />
                </div>

                <div className="field col-12">
                    <label htmlFor="dealCategory">Status</label>
                    <Dropdown value={statusTypeD} options={["Active", "Not Active"]} onChange={onStatusChange} placeholder={statusTypeD ? statusTypeD["name"] : "Select Status"} />
                </div>


            </Dialog>


            <Dialog visible={relistDealDialog} style={{ width: "450px" }} header="Confirm" modal footer={relistDealDialogFooter} onHide={hideRelistDealDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {dealDetails && (
                        <span>
                            Are you sure you want to active pages content <b>{dealDetails.shipping_product}</b> ?
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};
