import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CategoryService } from "../service/CategoryService";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import configData from "../config.json";

export const CategoryTable = () => {
  const fileInput = useRef();
  let emptyCategory = {
    name: "",
    image: null,
  };
  
  const [category, setCategory] = useState(null);
  const toast = useRef(null);
  const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
  const [relistCategoryDialog, setRelistCategoryDialog] = useState(false);
  const [activeCategoryDialog, setActiveCategoryDialog] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState(emptyCategory);
  const [edit, setEditUpdate] = useState(false);
  const [images, setImage] = useState("");
  const [cid, setCid] = useState("");
  
  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "category",
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService
      .getCategories(config)
      .then((data) => setCategory(data.data));
  }, []);

  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "category",
      headers: {
        "Content-Type": "application/json",
      },
    };
    categoryService
      .getCategories(config)
      .then((data) => setCategory(data.data));
  }, [
    deleteCategoryDialog,
    relistCategoryDialog,
    edit,
    categoryDetails,
    activeCategoryDialog,
  ]);
  const activestatusBodyTemplate = (rowData) => {
    if (rowData.status == "Active") {
      return (
        <>
          <span className={`product-badge status-instock`}>
            {rowData.status}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className={`product-badge status-outofstock`}>Not Active</span>
        </>
      );
    }
  };

  const hideDeleteCategoryDialog = () => {
    setDeleteCategoryDialog(false);
  };
  const hideActiveCategoryDialog = () => {
    setActiveCategoryDialog(false);
  };
  const hideRelistCategoryDialog = () => {
    setRelistCategoryDialog(false);
  };
  const deleteCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "delete",
      url: configData.SERVER_URL + "category/delete-category/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { _id: cid },
    };
    categoryService.deleteCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Category delete successfully",
          life: 3000,
        });
        setDeleteCategoryDialog(false);
        setCategoryDetails(emptyCategory);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while delete category",
          life: 3000,
        });
      }
    });
  };

  const activeCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "put",
      url: configData.SERVER_URL + "category/update/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Not Active" },
    };
    categoryService.deleteCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Category unlisted successfully",
          life: 3000,
        });
        setActiveCategoryDialog(false);
        setCategoryDetails(emptyCategory);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while unlisting category",
          life: 3000,
        });
      }
    });
  };

  const relistCategory = () => {
    const categoryService = new CategoryService();
    const config = {
      method: "put",
      url: configData.SERVER_URL + "category/update/" + cid,
      headers: {
        "Content-Type": "application/json",
      },
      data: { status: "Active" },
    };
    categoryService.relistCategory(config).then((data) => {
      if (data) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Category relisted successfully",
          life: 3000,
        });
        setRelistCategoryDialog(false);
        setCategoryDetails(emptyCategory);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while relisting category",
          life: 3000,
        });
      }
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _category = { ...categoryDetails };
    _category[`${name}`] = val;

    setCategoryDetails(_category);
  };

  const [categoryUpdateDialog, setCategoryUpdateDialog] = useState(false);
  const updateCategory = () => {
    const categoryService = new CategoryService();
    let errors = {};
    if (!categoryDetails.title) {
      errors.name = "Title is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.name,
        life: 3000,
      });
      return;
    }

    const body = {
      title: categoryDetails.title,
      image: images,
      content: categoryDetails.content,
    };
    if (edit === true) {
      const config = {
        method: "put",
        url: configData.SERVER_URL + "category/update/" + cid,
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      };
      categoryService.updateCategory(config).then((data) => {
        if (data.success ===  true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Category updated successfully",
            life: 3000,
          });
          setCategoryUpdateDialog(false);
          setCategoryDetails(emptyCategory);
          setEditUpdate(false);
          setCid("");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Error while updating category",
            life: 3000,
          });
        }
      });
      return;
    }
  };

  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };
  const onUpload = (event) => {
    const categoryService = new CategoryService();
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name.replace(/\..+$/, "");
    const category = {
      file: newFileName
    };

    let data = new FormData();
    data.append('file', file);
    
    const config = {
      method: "post",
      url: configData.SERVER_URL + "category/upload",
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      data: data,
    };
    categoryService.addCategory(config).then((data) => {
      if (data) {
        setImage(configData.SERVER_URL+'category/'+data.filename);
        let _category = { ...categoryDetails };
       _category["image"] = images;
       setCategoryDetails(_category);

    setCategoryDetails(_category);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Images added successfully",
          life: 3000,
        });
        
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new category",
          life: 3000,
        });
      }
    });
    
   
  };
 

  const categoryUpdateDialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          setCategoryUpdateDialog(false);
        }}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={updateCategory}
      />
    </>
  );

  const deleteCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteCategory}
      />
    </>
  );

  const activeCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideActiveCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={activeCategory}
      />
    </>
  );
  const relistCategoryDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideRelistCategoryDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={relistCategory}
      />
    </>
  );

  const editCategory = (category) => {
    setCategoryDetails({ ...category });
    setEditUpdate(true);
    setCid(category._id);
    setImage(category.image);
    setCategoryUpdateDialog(true);
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions"  style={{
        display: "flex"
      }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editCategory(rowData)}
        />
        {rowData.status == "Active" && (
          <Button
            icon="pi pi-lock "
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => {
              setCategoryDetails(rowData);
              setCid(rowData["_id"]);
              setActiveCategoryDialog(true);
            }}
          />
        )}
        {rowData.status == "Not Active" && (
          <Button
            icon="pi pi-unlock"
            className="p-button-rounded p-button-info mr-2"
            onClick={() => {
              setCategoryDetails(rowData);
              setCid(rowData["_id"]);
              setRelistCategoryDialog(true);
            }}
          />
        )}
        {
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning mr-2"
            onClick={() => {
              setCategoryDetails(rowData);
              setCid(rowData["_id"]);
              setDeleteCategoryDialog(true);
            }}
          />
        }
      </div>
    );
  };

  const [selectedStatF, setSelectedStatF] = useState(null);
  const dt = useRef(null);
  const statItemTemplate = (option) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "capitalize",
        }}
      >
        {option}
      </span>
    );
  };
  const onStatChange = (e) => {
    dt.current.filter(e.value, "status", "equals");
    setSelectedStatF(e.value);
  };
  const statFilter = (
    <Dropdown
      value={selectedStatF}
      options={["Active", "Not Active"]}
      onChange={onStatChange}
      itemTemplate={statItemTemplate}
      placeholder="Select a Status"
      option
      showClear
      style={{ width: "110px", fontSize: "10px" }}
    />
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const reset = () => {
    setGlobalFilter("");
    dt.current.reset();
  };
  const header = (
    <div className="table-header">
      <Button
        type="button"
        label="Clear"
        className="p-button-outlined"
        icon="pi pi-filter-slash"
        onClick={reset}
      />
      <span className="p-input-icon-left" style={{ float: "right" }}>
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search"
          style={{ marginRight: "15px" }}
        />
      </span>
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <h5>Categories</h5>
            <DataTable
              ref={dt}
              value={category}
              className="datatable-responsive"
              rows={10}
              paginator
              globalFilter={globalFilter}
              header={header}
              dataKey="_id"
            >
              <Column
                header="Image"
                body={(data) => <img src={data.image} alt={data} width="50" />}
                style={{ fontSize: "12px"}}
              />
             
              <Column
                field="title"
                header="Name"
                body={(rowData) => (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      
                    >
                      {rowData && rowData.title}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px"}}
              />
              <Column
                field="createdAt"
                header="Date"
                body={(rowData) => (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      {rowData && new Date(rowData.createdAt).toLocaleString()}
                    </span>
                  </>
                )}
                sortable
                style={{ fontSize: "12px" }}
              />
              <Column
                field="status"
                header="Status"
                body={activestatusBodyTemplate}
                filter
                filterElement={statFilter}
                style={{ fontSize: "12px"}}
              />
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>

    
     
      <Dialog
        visible={categoryUpdateDialog}
        style={{ width: "550px" }}
        header="Category Details"
        modal
        className="p-fluid"
        footer={categoryUpdateDialogFooter}
        onHide={() => {
          setCategoryUpdateDialog(false);
        }}
      >
        {categoryDetails.image && (
          <img
            src={categoryDetails.image}
            alt={categoryDetails.image}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
        <div className="field col-12">
          <label htmlFor="categoryImage">Category image</label>
          <FileUpload
            ref={fileInput}
            name="demo"
            customUpload={true}
            uploadHandler={onTemplateUpload}
            onUpload={onTemplateUpload}
            accept="image/*"
            maxFileSize={1000000}
            emptyTemplate={
              <p className="p-m-3" style={{ fontSize: "15px" }}>
                Drag and drop files to here to upload.
              </p>
            }
            auto
          />
        </div>
        <div className="field">
          <label htmlFor="name">Title</label>
          <InputText
            id="name"
            value={categoryDetails.title}
            onChange={(e) => onInputChange(e, "title")}
            required
            autoFocus
          />
        </div>
        <div className="field">
          <label htmlFor="desription">Description</label>
          <InputText
            id="desription"
            value={categoryDetails.content}
            onChange={(e) => onInputChange(e, "content")}
            required
            autoFocus
          />
        </div>
      </Dialog>
      <Dialog
        visible={deleteCategoryDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={deleteCategoryDialogFooter}
        onHide={hideDeleteCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to delete category{" "}
              <b>{categoryDetails.title}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={activeCategoryDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={activeCategoryDialogFooter}
        onHide={hideActiveCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to unlist category{" "}
              <b>{categoryDetails.title}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={relistCategoryDialog}
        style={{ width: "450px" }}
        header="Confirm"
        modal
        footer={relistCategoryDialogFooter}
        onHide={hideRelistCategoryDialog}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {categoryDetails && (
            <span>
              Are you sure you want to relist category{" "}
              <b>{categoryDetails.title}</b> ?
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};
