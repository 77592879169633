import React,{useState,useLayoutEffect}  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
export const AppTopbar = (props) => {
    const [openProfile,setProfile] = useState(false)
    const [openSettings,setSettings] = useState(false)
    const [users, setUsers] = useState(null);
     
    useLayoutEffect(() => {
       setUsers(props.profile);
    }, [props.profile]);
    
    return (
        <>
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                {/* <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo.png' : 'assets/layout/images/logo.png'} alt="logo"/> */}
                <span>PTC</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={()=>{setSettings(true)}} >
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={()=>{setProfile(true)}}>
                            <i className="pi pi-user"/>
                            <span>Profile</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={()=>{sessionStorage.clear();window.location.href="https://storage.googleapis.com/mbewebsite/index.html#/";window.location.reload()}}>
                            <i className="pi pi-sign-out"/>
                            <span>Log out</span>
                        </button>
                    </li>
                </ul>
        </div>
        <Dialog visible={openProfile} style={{ width: "450px" }} header="Admin Profile" modal  onHide={()=>{setProfile(false)}}>
                <div className="flex align-items-center justify-content-left">
                    <i className="pi pi-user mr-3" style={{ fontSize: "2rem" }} />
                        <h1 style={{fontSize: "1rem",fontWeight:600}}>Email:  <span style={{fontSize:"0.9rem",fontWeight:500}}>
                            <pre>{users && users.email}</pre>
                        </span>
                        </h1><br/>
                </div>
                <div className="flex align-items-center justify-content-left">
                <i className="pi pi-id-card mr-3" style={{ fontSize: "2rem" }} />
                        <h1 style={{fontSize: "1rem",fontWeight:600}}>Last login:  <span style={{fontSize:"0.9rem",fontWeight:500}}>
                            {new Date().getDate()}/{new Date().getMonth()+1}/{new Date().getFullYear()} at {new Date().getHours()}:{new Date().getMinutes()}:{new Date().getSeconds()}
                            
                        </span>
                        </h1>
                </div>
            </Dialog>
            <Dialog visible={openSettings} style={{ width: "450px" }} header="Admin Settings" modal  onHide={()=>{setSettings(false)}}>

                <div className="flex align-items-center justify-content-left">
                        <h1 style={{fontSize: "1rem",fontWeight:600}}>Current Password:<br/><br/><InputText id="settings" type="password" onChange={(e)=>{}} style={{marginLeft:"auto",marginRight:"auto",display:"flex",marginBottom:"12px",minWidth:"100%"}}/>
                        </h1>
                </div>
                <div className="flex align-items-center justify-content-left">
                        <h1 style={{fontSize: "1rem",fontWeight:600}}>New Password:<br/><br/><InputText id="settings" type="password" onChange={(e)=>{}} style={{marginLeft:"auto",marginRight:"auto",display:"flex",marginBottom:"12px",minWidth:"100%"}}/>
                        </h1>
                </div>
                <div className="flex align-items-center justify-content-left">
                        <h1 style={{fontSize: "1rem",fontWeight:600}}>Confirm Current Password:<br/><br/><InputText id="settings" type="password" onChange={(e)=>{}} style={{marginLeft:"auto",marginRight:"auto",display:"flex",marginBottom:"12px",minWidth:"100%"}}/>
                        </h1>
                </div>
                <div className="flex align-items-center justify-content-center">
                <Button label="Change Password" icon="pi pi-user" className="p-button-info"  style={{marginLeft:"auto",marginRight:"auto",display:"flex",marginBottom:"12px",width:"100%"}}/>
                </div>
                
            </Dialog>
        </>
    );
}
