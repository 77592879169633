import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import "./customButton.css";
import { CategoryService } from "../service/CategoryService";
import { Toast } from "primereact/toast";
import configData from "../config.json";
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';


export const SongsFormLayout = () => {
  const fileInput = useRef();
  const songsInput = useRef();
  const videoInput = useRef();
  const toast = useRef(null);
  const [name, setName] = useState("");
  const [author, setAuthor] = useState("");
  const [album, setAlbum] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [video, setVideoFile] = useState("");
  const history = useHistory();
  const [category, setCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [value, setValue] = useState(0);
  const interval = useRef(null);
  const [showSongsP, setValueSongsP] = useState(false);

  const [valueVideo, setValueVideo] = useState(0);
  const intervalVideo = useRef(null);
  const [showVideoP, setValueVideoP] = useState(false);

  useEffect(() => {
    const categoryService = new CategoryService();
    const config = {
        method: 'get',
        url: configData.SERVER_URL+'category',
        headers: {
            'Content-Type' : 'application/json'
        }
    }
    categoryService.getCategories(config).then(data => setCategory(data.data));
}, []);

  const onCategoryChange = (e) => {
    setSelectedCategory(e.value);
}

  const onTemplateUpload = (e) => {
    onUpload(e.files);
  };

  const onUpload = (event) => {
    const categoryService = new CategoryService();
    let file = fileInput.current.files[0];
    
    let data = new FormData();
    data.append('file', file);
    
    const config = {
      method: "post",
      url: configData.SERVER_URL + "songs/upload",
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      data: data,
    };
    categoryService.addCategory(config).then((data) => {
      if (data) {
        setImage(data.filename);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Images added successfully",
          life: 3000,
        });
        
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new images",
          life: 3000,
        });
      }
    });
    
   
  };

  const onSongsUpload = (e) => {
    onUploadSongs(e.files);
  };

  const onTemplateClear = (e) => {
    console.log("calling clear");
    setValueSongsP(false);
  };

 

  const onUploadSongs = (event) => {
    let _val = value;
    const categoryService = new CategoryService();
    let file = songsInput.current.files[0];
    let data = new FormData();
    data.append('file', file);
    setValueSongsP(true);

    interval.current = setInterval(() => {
      _val += Math.floor(Math.random() * 10) + 1;

      if (_val >= 100) {
          _val = 100;
          toast.current.show({ severity: 'info', summary: 'Success', detail: 'Process Completed' });
          clearInterval(interval.current);
      }

      setValue(_val);
  }, 2000);
    const config = {
      method: "post",
      url: configData.SERVER_URL + "songs/upload",
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      data: data,
    };
    categoryService.addCategory(config).then((data) => {
      if (data) {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
        let _val = 100;
        setValue(_val);
        setFile(data.filename);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Songs added successfully",
          life: 3000,
        });
        
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new files",
          life: 3000,
        });
      }
    });
    
   
  };

  const onVideoUpload = (e) => {
    onUploadVideo(e.files);
  };

 

  const onUploadVideo = (event) => {
    let _val = valueVideo;
    const categoryService = new CategoryService();
    let file = videoInput.current.files[0];
    
    let data = new FormData();
    data.append('file', file);
    setValueVideoP(true);

    intervalVideo.current = setInterval(() => {
      _val += Math.floor(Math.random() * 10) + 1;

      if (_val >= 100) {
          _val = 100;
          toast.current.show({ severity: 'info', summary: 'Success', detail: 'Process Completed' });
          clearInterval(intervalVideo.current);
      }

      setValueVideo(_val);
    }, 2000);
    
    const config = {
      method: "post",
      url: configData.SERVER_URL + "songs/upload",
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      },
      data: data,
    };
    categoryService.addCategory(config).then((data) => {
      if (data) {
        if (intervalVideo.current) {
          clearInterval(intervalVideo.current);
          intervalVideo.current = null;
        }
        let _val = 100;
        setValueVideo(_val);
        setVideoFile(data.filename);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Video added successfully",
          life: 3000,
        });
        
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new video",
          life: 3000,
        });
      }
    });
    
   
  };
 

  

  const onSubmit = () => {
    const categoryService = new CategoryService();

    let errors = {};

    if (!name) {
      errors.name = "Title is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.name,
        life: 3000,
      });
      return;
    }

  if (!image) {
      errors.image = "image is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.image,
        life: 3000,
      });
      return;
    }
    if (!file) {
      errors.image = "file is required.";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errors.file,
        life: 3000,
      });
      return;
    }

    const songs = {
      title: name,
      image: configData.SERVER_URL+'songs/'+image,
      content: content,
      url: configData.SERVER_URL+'songs/'+file,
      videourl: configData.SERVER_URL+'songs/'+video,
      filename: file,
      album: album,
      author: author,
      category: selectedCategory._id
    };
    const config = {
      method: "post",
      url: configData.SERVER_URL + "songs/add_songs",
      headers: {
        "Content-Type": "application/json",
      },
      data: songs,
    };
    categoryService.addCategory(config).then((data) => {
      if (data.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Songs added successfully",
          life: 3000,
        });
        history.push("/managesongs");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error while adding a new songs",
          life: 3000,
        });
      }
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h5>Add Song</h5>
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="categoryName">Song title</label>
                <InputText
                  id="songsName"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="categoryContent">Description</label>
                <InputText
                  id="songsContent"
                  type="text"
                  onChange={(e) => setContent(e.target.value)}
                />
              </div>
              <div className="field col-12" style={{marginTop:"20px"}}> 
                    <label htmlFor="dealName">Category</label>
                    <Dropdown value={selectedCategory} options={category} onChange={onCategoryChange} optionLabel="title" placeholder="Select a Category" />
              </div>
              <div className="field col-12">
                <label htmlFor="categoryImage">Song image</label>
                <FileUpload
                  ref={fileInput}
                  name="demo"
                  customUpload={true}
                  uploadHandler={onTemplateUpload}
                  onUpload={onTemplateUpload}
                  accept="image/*"
                  maxFileSize={1000000}
                  emptyTemplate={
                    <p className="p-m-3" style={{ fontSize: "15px" }}>
                      Drag and drop files to here to upload.
                    </p>
                  }
                  auto
                />
              </div>
              <div className="field col-12">
                <label htmlFor="songAlbum">Song album</label>
                <InputText
                  id="songAlbum"
                  type="text"
                  onChange={(e) => setAlbum(e.target.value)}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="songAuthor">Song author</label>
                <InputText
                  id="songAuthor"
                  type="text"
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>

              <div className="field col-12">
                <label htmlFor="songFile">Song File</label>
                <FileUpload
                  ref={songsInput}
                  name="demo"
                  customUpload={true}
                  uploadHandler={onSongsUpload}
                  onUpload={onSongsUpload}
                  onClear={onTemplateClear}
                  accept="audio/mpeg3"
                  //maxFileSize={1000000}
                  emptyTemplate={
                    <p className="p-m-3" style={{ fontSize: "15px" }}>
                      Drag and drop files to here to upload.
                    </p>
                  }
                  auto
                />
                { showSongsP === true && <Toast ref={toast}></Toast> }
                { showSongsP === true && <ProgressBar value={value}></ProgressBar> }
              </div>
            
              <div className="field col-12">
                <label htmlFor="songFile">Video File</label>
                <FileUpload
                  ref={videoInput}
                  name="video"
                  customUpload={true}
                  uploadHandler={onVideoUpload}
                  onUpload={onVideoUpload}
                  accept="video/*"
                  //maxFileSize={1000000}
                  emptyTemplate={
                    <p className="p-m-3" style={{ fontSize: "15px" }}>
                      Drag and drop files to here to upload.
                    </p>
                  }
                  auto
                />
                { showVideoP === true && <Toast ref={toast}></Toast> }
                { showVideoP === true && <ProgressBar value={valueVideo}></ProgressBar> }
              </div>
            
              <div className="field col-12">
                <Button
                  className="p-button-rounded formButton1"
                  onClick={onSubmit}
                >
                  Add song
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
